.foot{
    height: 35vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    text-transform: uppercase;
    font-weight: normal;
}
p{
    text-transform: capitalize;
}
.foot1{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-self: baseline;
    margin-top:100px;
    text-align: left;
    font-size: 15px;
}
h3{
    cursor: pointer;
}
h3:hover{
    color: #f0f907;
}