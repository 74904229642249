.player{
    height: 50vh;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.play{
    background: black;
    width: 80%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 25px;
}
.play h2{
    color: #fff;
    font-size: 75px;
}
.play .count{
    max-width: 300px;
} 
.play .count img{
    width: 100%;
}
.slide-btn{
    margin-top: 25px;
}
.slide-btn span{
    display: flex;
    gap:25px;
}
 i{
    color: #fff;
    font-size:22px;
    cursor: pointer;
}