.card1{
    width: 600px;
    height: 100px;
    background: rgb(44, 176, 238);
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    color: rgb(25, 25, 109);
    text-transform: capitalize;
    border-radius: 20px;
    position: relative;
    justify-content: center;
}
.card0{
    width: 600px;
    height: 100px;
    background: rgb(44, 176, 238);
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    color: rgb(25, 25, 109);
    text-transform: capitalize;
    border-radius: 20px;
    align-self: flex-end;
    position: relative;
    justify-content: center;
}
.head1{
    position: absolute;
    top: -20px;
    left: 0;
    color: white;
    font-size: 35px;
    font-weight: lighter;
}
.head2{
    position: absolute;
    top: -20px;
    color: white;
    right: 0;
    font-size: 35px;
    font-weight: lighter;
}
