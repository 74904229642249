.pcs{
    height: 100vh;
    position: relative;
}
.pcs-mid{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width:50%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap:50px;
}
.one{
    align-self: flex-start;
}
.two{
    align-self: flex-end;
}
.three{
    align-self: flex-start;
}
