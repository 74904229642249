.comm{
    height: 50vh;
    width: 100%;
    background: #7656bd;
    margin-top: 100px;
    position: relative;
}

.comm-mid{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 75%;
    height: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    background: #1d1e5f;
    border-radius: 20px;
}
.comm-mid h1{
    color: #fff;
    font-size: 50px;
    text-transform: capitalize;
}
.comm-mid p{
    color: rgb(230, 230, 230);
    width: 75%;
    text-align: center;
}